.toggle-container {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
    font-size: 16px;
    margin-bottom: 16px;
    gap: 10px;
  }
  
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 47px;
    height: 20px;
    margin-right: 16px;
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;
    padding: 2px; 
  }
  
  .toggle-slider:before {
    position: absolute;
    content: "";
    height: 14px;  
    width: 15px;  
    left: 2px; 
    bottom: 3px; 
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .toggle-slider {
    background-color: #2196F3;
  }
  
  input:focus + .toggle-slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .toggle-slider:before {
    transform: translateX(26px);
  }

