.main-container {
    width: 500px;
    position: relative;
    transition: all 5s ease-in-out;
}

.hrSection {
    z-index: 1;
    position: absolute;
    left: 8%;
    border-left: 2px solid black;
    height: 750px;
}

.togglrArrow {
    position: absolute;
    color: white;
    background-color: #fc6225;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    text-align: center;
    left: -10px;
    top: 7px;
    padding: 0px 1px 0px 0px;
    border: 2px solid #ccc;
}

button.btn.togglrArrow img {
    margin-top: -6px;
}

.mainHeading {
    color: #fc6225;
    padding-bottom: 4px;
    border-bottom: 3px solid #fc6225;
}

.rightTopButton {
    background-color: #eec4b528;
    padding: 4px 12px;
    border: none;
}

.containedImgDiv {
    margin-bottom: auto;
    z-index: 1;
}

.containedImg {
    object-fit: cover;
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
}

.statusColor {
    color: #fc6225;
}

.detail-contain {
    background-color: #b6bee07a;
}