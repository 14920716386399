.external_home_user .rs_master_canvas {
   padding: 0;
}

.external_home_user {
    background: #F7F9FB;
    height: auto ;
}

.feed_identity_user .profile_image {
    margin: -38px auto 1.2rem;  
}

.feed_identity_user .card-img-top {
    height: 80px;
}

.feed_identity_user .card{
    width: 100%;
    max-width: 327px; 
    min-height: 325px;
    background: #FFFFFF;
    border: 1px solid #DEE8F4;
    border-radius: 4px;
}

.feed_identity_user .card h3{ 
font-size: 18px;
color: #000000;
}

.feed_identity_user .card p {  
font-size: 16px;
color: #565656;
padding: 5px; 
}

.feed_identity_user .card button {
    width: 162px;
    height: 40px;
    background: #FC5E24;
    border-radius: 4px;
    border: none; 
    font-size: 16px; 
    margin-bottom: 1rem;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #FFFFFF;

}

.request_info_user {
    width: 100%;
    max-width: 327px; 
    min-height: 325px; 
    background: #FFFFFF;
border: 1px solid #DEE8F4;
border-radius: 4px;
}

.rate_user { 
    width: 100%;
    max-width: 327px; 
    min-height: 325px;  
     background: #FFFFFF;
    border: 1px solid #DEE8F4;
    border-radius: 4px;
}

.rate_user h3 {
    font-size: 18px; 
    font-weight: 600;
    color: #000000; 
}

.request_info_user  h3 {
    font-size: 18px; 
    font-weight: 600;
    color: #000000; 
}

.user_feed_area .feed_header {
    background: #fff;
}

.user_feed_area .feed_header .lynkaz_tab .lynkaz_tab_nav .lynkaz_tab_nav_item p {
  font-size: 14px;
  color: #8C8C8C;
}

.user_feed_area .feed_header .lynkaz_tab .lynkaz_tab_nav .lynkaz_tab_nav_item.active p {
    font-size: 14px;
    color: #000000;
}

.user_feed_area .user_feed_info_tab {
    background: #FFFFFF;
    width: 200px;
height: 89px;
box-shadow: 0px 4px 15px -2px rgba(0, 0, 0, 0.12);
 margin-bottom: 1.5rem;
margin-right: 1rem;  
}

.user_feed_area .user_feed_info_tab h2 {   
    font-size: 24px;
    line-height: 16px; 
    color: #FF4697;
}

.user_feed_area .user_feed_info_tab p { 
    font-size: 14px;
    line-height: 12px; 
    color: #4E5969;
}

.user_feed_area .user_feed_info_tab .icon{
    width: 58px;
    height: 58px;
}

.feed_tabs_items {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: flex-start;
}

.feed_header   {
    background: #FFFFFF;
    border: 1px solid #DEE8F4;
    border-radius: 4px;
} 

.feed_header .invoice_btn {
   float: right;
   margin-bottom: 1rem; 
   margin-right: 1.3rem; 
}

.feed_header .invoice_btn button {
    border: none;
    background: none;
    color: #FC5E24;
    font-size: 14px;
}

.invoice_info_header {
    width: auto;
height: 33px;
background: #29304C;
margin-bottom: 1rem;
}

.feed_tabs_invoice .invoice_info_header h2{
    font-size: 14px; 
    color: #FFFFFF;  
}

.invoice_info_header_close {
    float: right;
    margin-top: 0.1rem;
    cursor: pointer;
}

.user_feeds { 
    height: 583px;
    background: #FFFFFF;  
box-shadow: 0px 0px 2px 1px rgba(112, 112, 112, 0.2);
border-radius: 10px;
}
.user_feeds h2 {
    font-size: 20px;
line-height: 20px; 
color: #191919;
}

.user_feeds .company_info  p {
    font-size: 16px;
line-height: 16px; 
color: #707070; 
}

.user_feeds .company_info  p span img{
    cursor: pointer;
}

.user_feeds .post_msg {
    font-size: 14px;
line-height: 20px; 
color: #191919; 
}

.user_feeds .post_image {
    height: 231px;
   background-image: url('./image.png');
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
}

.post_image_short_desc {
    background: #EEF3F8; 
height: 60px; 
}

.post_image_short_desc h3 {
    font-size: 14px;
    margin-top: 0.6rem;
    font-weight: 600;
color: #191919;
}

.post_image_short_desc p {
    font-size: 12px; 
color: #707070;
}

.reaction-elements {
    height: 40px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
}

.reaction-elements img {
    position: relative;
    top: 0;
    transition: top ease 0.5s;
}

.reaction-elements img:hover {
    top: -10px;
}

.reaction-elements h3 {
    font-size: 12px; 
color: #707070;
}

.user_reaction_post span {
    font-size: 14px;
line-height: 28px; 
color: #707070;
cursor: pointer;
user-select: none;
font-weight: 600;
}

.user_reaction_post img {
    cursor: pointer;
    user-select: none;
}


