.procurement_settings { 
    padding-bottom: 10em;  
    
 } 

.procurement_settings h2 {  
font-weight: 600;
font-size: 16px;
line-height: 24px; 
color: #000000; 
width: 114px;
border-bottom: 1px solid #2D74E0; 
}

.rs_delivery_term .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_delivery_mode .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

 .chips-list-page {
    display: inline-block;
    padding: 0 15px;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    font-weight: 400;
    color: white; 
    border-radius: 15px;
    background-color: #2D74E0;
}

.columns {
    columns: 1 auto;
}

.team_header_lines .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.team_header_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.number_sequence_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_team_header .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.Controls h5  {  
    font-weight: 400;
    font-size: 14px; 
    letter-spacing: 0.02em; 
    color: #1F1F1F; 
    line-height: 1
} 

.Controls p { 
    font-weight: 400;
    font-size: 12px;
    line-height: 24px; 
    color: #7E7E7E;
} 

.border-controls {
    height: 2.6rem;
   border-left:  1px solid #DEE8F4;;
}

@media (max-width: 992px) {
    .procurement_settings {
        max-height: auto;
        min-height: 120vh;
    }
}