.header_line { 
    width: auto;
height: 43px;
background: #29304C;
display: flex;
justify-content: start;
align-items: center;
}
.header_line h2 {
    font-weight: 600;
font-size: 18px;  
color: #FFFFFF;
padding-left: 12px;
padding-bottom: 10px;
padding-top: 15px;
}