.purchase_orders_listing_archive {
    background: #F7F9FB;
}
.purchase_orders_detail_view {
    background: #F7F9FB;
}
 .purchase_orders_listing_archive .rs_ag_header {
    background-color: #fff;
 }

 .purchase_orders_listing_archive .ag_grid_footer {
    background-color: #fff;
 }

 .purchase_orders_listing_archive .ag_grid_wrapper { 
    background-color: #fff;
 }
 
 .rs_h_controllers .rs_h_actions {
    width: 500px;
 }

 .grid_btns_ext_po {
    width:  277px !important;
 }

 .purchase_order_section {
    height: auto; 
    background: #FFFFFF;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 7px;
    padding-bottom: 30px;
 }

 .purchase_order_section .archive_header_section {
    background: #fff;
    border-bottom: none;
 }

 .purchase_orders_detail_view {
    height: 210vh;
 }

 .purchase_order_section .archive_header_section h3 {
    font-weight: 700;
font-size: 20px; 
color: #000000;
 }

 .purchase_order_section .archive_header_section h3 span {
    color: #FC5E24;
 }

 .purchase_order_status { 
    padding-top: 12px;
    padding-bottom: 12px; 
    background-color: rgba(252, 94, 36, 0.05);
border-radius: 2.08459px;
height: 228px ;
 }

 .purchase_order_status h2 {
    font-weight: 600;
  font-size: 14px;  
  color: #000000;
  }

  .purchase_order_status h2 span  {
    font-weight: 400;
font-size: 14px; 
color: #000000;

  }

  .purchase_order_status h2 span button { 
background: #32C472;
border: none;
border-radius: 4px;
width: 88px;
height: 27px;
color: #fff;
  }

  .purchasing_items h2 {
    font-weight: 700;
font-size: 20px; 
color: #000000;
  }

  .line_head {
    background: #FC5E24;
border-radius: 2.08459px;
height: 34px;
  }

  .purchase_lines .line_head h4 {
    font-weight: 700;
    line-height: 30px;
font-size: 14px; 
color: #FFFFFF;
  }

.purchase_order_section .archive_header_section { 
    z-index: 100 !important;
}

.line_head_data {
    background: #F4F7F6;
border-radius: 2.08459px;
height: 46.5px; 
border-bottom: 1px solid #EBEFF2;
}

.purchase_lines .line_head_data  h4  {
    font-weight: 400;
font-size: 14px; 
letter-spacing: 0.02em; 
color: #050505;
}

.attachments_po {
    background: #ffffff;
height: 389px; 
padding-top: 12px;
padding-bottom: 12px; 
padding-right: 13px;
padding-left: 13px;
}

.attachments_po h3 {
    font-weight: 700;
font-size: 20px;
line-height: 30px; 
color: #000000;
}

.notes_attachments {
    background: #ffffff;
height: 389px; 
padding-top: 12px;
padding-bottom: 12px; 
padding-right: 13px;
padding-left: 13px;
}

.notes_attachments h3 {
    font-weight: 700;
font-size: 20px;
line-height: 30px; 
color: #000000;
}

.notes_attachments .note_attachment_history_widget {
    border: none;
}

.line_head_attachments {
    height: 34px;
    background: #222934;
border-radius: 2.08459px; 
}

.purchase_orders_attachments .line_head_attachments h4 {
    font-weight: 700;
font-size: 14px;
line-height: 30px; 
color: #FFFFFF;
}

.line_head_attachments_data {
    height: 65.16px;
    background: #FFFFFF;
    border-bottom: 1px solid  #DEE8F4; 
}

.line_head_attachments_data h4 {
    font-weight: 400;
font-size: 14px;
color: #050505;
}

 .line_head_attachments_data .action {
    cursor: pointer;
 }

.line_head_attachments_data  h3{
    font-weight: 600;
    font-size: 14.5756px; 
    color: #242634;
}

.pdf_icon {
    width: 25px;
height: 25px;
margin-top: 1rem;
} 