body {
	

}
.login_register_page {
	background-image: url('../../../public/images/log_reg_bg.svg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-attachment: fixed;
	background-color: #F7F8FA;
	display: block;
	height: 100vh;
	width: 100vw;
	overflow-x: hidden;
	margin: 0;
	padding: 0;
	overflow-y: auto;
}
.login_header {
	padding: 20px 0;
}
.login_header .login_logo{}
.login_header .login_logo img{}
.login_regiser_box {
	min-height: calc(100vh - 150px);
	padding: 100px 0 30px 0;
    /*
	display: flex;
	align-items: center;
    */
}
.lr_box_inner {
	display: block;
	width: 466px;
    max-width: 100%;
	margin: 0 auto;
	background: #fff;
	box-sizing: border-box;
	box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.12);
}
.lr_box_inner .log_reg_th {
	display: flex;
	width: 100%;
	background: #EEEEEE;
	justify-content: space-around;
}
.lr_box_inner .log_reg_th a {
	/* font-family: 'Titillium Web'; */
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	color: #181818;
	padding: 11px 0;
	display: block;
	width: 50%;
    cursor: pointer;
}
.lr_box_inner .log_reg_th a.active{
    background-color: #FC5E24;
    color: #fff;
}
.login_form_group {
	padding: 44px 50px 50px 50px;
	margin: 0;
	display: block;
	box-sizing: border-box;
}
.login_form_group .form-group{}
.login_form_group .form-group+.form-group{
    margin-top: 20px;
}
.login_form_group .form-group .form-control {
	display: block;
	height: 45px;
	font-size: 12px;
	font-weight: 400;
	color: #949494;
	/* font-family: 'Titillium Web'; */
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #D8D8D8;
	padding-left: 15px;
}
.login_form_group .form-group.invalid .form-control{
	border-color: #D73535;
}
.login_form_group .form-group .form-control:focus,
.login_form_group .form-group.invalid .form-control:focus{
	border-color: #2D74E0;
}
.login_form_group .form-group label{
    /* font-family: 'Titillium Web'; */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: block;
    align-items: center;
    color: #333333;
    margin: 0 0 5px 0;
}
.login_form_group .form-group .form-text {}
.login_form_group .form-group .form-text.danger{}
.login_form_group .form-group  input::placeholder {
    color: #949494;
}
.lr_box_inner .btn {
	height: 45px;
	border: 0.5px solid #7D879E;
	border-radius: 2px;
	/* font-family: 'Titillium Web'; */
	font-size: 12px;
	color: #58585A;
	padding: 0 20px;
    box-shadow:  none !important;
    outline:  none !important;
    transition: all .5s;
}
.lr_box_inner .btn+.btn{
    margin-top: 10px;
}
.lr_box_inner .btn .spinner-border{
	position: relative;
	top:2px;
	margin-left: 5px;
}
.lr_box_inner .btn.sb_btn {
	font-weight: 600;
	font-size: 14px;
	text-shadow: none !important;
    margin-top: 30px;
}
.lr_box_inner .btn_block{
    width: 100%;
}

.lr_box_inner .btn_blue {
	color: #fff;
	background: #2D74E0;
	border-color: #2D74E0;
}
.lr_box_inner .btn:hover,
.lr_box_inner .btn_blue:hover{
    background-color: #FC5E24;
    border-color: #FC5E24;
    color: #fff;
}
.lf_title_subtitle {
	margin: 0 0 21px 0;
	padding: 0;
}
.lf_title_subtitle h2 {
	/* font-family: 'Titillium Web'; */
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 33px;
	color: #333333;
	margin: 0;
	padding: 0;
	display: block;
}
.lf_title_subtitle p {
	/* font-family: 'Titillium Web'; */
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 23px;
	color: #757575;
	margin: 0;
}
.btn_login_with_google{}
.btn_login_with_google img {
	height: 25px;
	margin-right: 5px;
	display: inline-block;
}
.login_form_group .pwr_link {
	margin: 11px 0 0 0;
	padding: 0;
	display: block;
	text-align: right;
}
.login_form_group .pwr_link a{
	/* font-family: 'Titillium Web'; */
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #2D74E0;
	text-decoration: none;
	display: inline-block;
	transition: all .5s;
}
.login_fooer .log_footer_menu a:hover,
.login_form_group .pwr_link a:hover{
	color: #FC5E24;
}
.login_fooer {
	display: block;
	padding: 20px 0;
}
.login_fooer .log_footer_menu {
	display: flex;
	margin: 0;
	padding: 0;
}
.login_fooer .log_footer_menu a {
	/* font-family: 'Titillium Web'; */
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	color: #000000;
	text-decoration: none;
	transition: all .5s;
	padding-right: 15px;
	margin-right: 15px;
	border-right: 2px solid #DCDCDC;
	line-height: 20px;
}
.login_fooer .log_footer_menu a:last-child{
	border-right: 0 none;
}
.login_fooer .log_footer_menu a img {
	height: 13px;
	margin-right: 5px;
}
.login_fooer .log_footer_menu a span{}
