.background-layout-forms { 
    min-height: 100vh;
    max-height: auto;
}
/* .users h1{
    font-family: 'Titillium Web';
font-style: normal;
font-weight: 600;
font-size: 18px;
color: #333333;

} */

.user-roles-section {
    border: 1px solid #DEE2E6;
    min-height: 60vh;
    max-width: auto; 
}

.selected_role {
	background: transparent;
	color: #ffffff;
	/* font-family: 'Titillium Web'; */
	font-size: 16px;
	font-weight: 600;
	border-radius: 4px;
	border: 1px solid #FC5E24;
	padding: 7px 20px;
  transition: all .5s;
  display: inline-flex;
  align-items: center;
  gap: 3px;
  background-color: #FC5E24;
}
.selected_role:hover{
  opacity: .8;
  box-shadow: none;
  outline: none;
}
.selected_role.btn_active{
  background-color: #FC5E24;
  color: #fff;
  border-color: #FC5E24;
}
 
.roles-buttons{
    /* font-family: 'Titillium Web'; */
font-style: normal;
font-weight: 600;
font-size: 16px; 
color: #000000;
}

.add-user-role {
    /* font-family: 'Titillium Web'; */
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    background-color: transparent;
    border: none;
    color: #000000;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    display: inline-block; 
    border-radius: 2px;
}

.roles-icons {
    width: 22px;
    height: 22px;
}

.delete-role {
    /* font-family: 'Titillium Web'; */
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    background-color: transparent;
    border: none;
    color: #000000;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    display: inline-block; 
    border-radius: 2px;
}

.relation {
    /* font-family: 'Titillium Web'; */
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    background-color: transparent;
    border: none;
    color: #000000;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    display: inline-block; 
    border-radius: 2px;
}

.assign-org {
    /* font-family: 'Titillium Web'; */
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    background-color: transparent;
    border: none;
    color: #000000;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    display: inline-block; 
    border-radius: 2px;
}
.user-roles-section {
    border: 1px solid #DEE2E6;
    min-height: 60vh;
    max-width: auto;
}
 
.users-fields-section {
    border: 1px solid #DEE2E6;
    height: auto;
}
.icons-dropdown {
    margin-left: 6.3rem;
}
/* 
.users-fields-form label {
    font-family: 'Titillium Web';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 24px;
display: flex;
align-items: center;
color: #7E7E7E;
}
  
.users-fields-form input[type='text'] {
    border-bottom: 1px solid #7E7E7E;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
    width: 100%;
}

input::placeholder {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
}

input {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
} 
  */