.general_tab_item_create .lynkaz_tab_nav_section{
    z-index: 100 !important;
}

.price_discount .lynkaz_tab_nav_section{
    z-index: 100 !important;
}

.discount .lynkaz_tab_nav_section{
    z-index: 100 !important;
}

.vendor .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.entity .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.identification label span {
    color:#D73535;
}

.identification .rs_h_controller {
    background-color: transparent;
    border: none;
}

.descriptionTextarea {
    width: 100%;
    min-height: 100px;
    max-height: 120px;
}