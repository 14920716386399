.popup_open{
    overflow: hidden;
}
.address-modal-overlay{  
    background: rgba(0, 0, 0, 0.56);
    padding: 1rem; 
    width: 100%;
    height: 100% ;
    position: fixed;
    overflow: hidden;
    min-height: 100%;
    z-index: 1000;
    left: 0;
    top: 0;

}
.address_pop_inner {
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
}
.close-modal button {
    width: 30px;
    height: 29px;
    border-radius: 50px;
    color: #000000;
    border: none
}
 