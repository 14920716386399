.user-main-container {
    width: 512px;
    height: 332px;
    position: relative;
    background-color: #fff;
    border: 2px solid #f5f5f5 !important;
}

.userImg {
    object-fit: cover;
    max-width: 70px;
    max-height: 70px;
    min-width: 70px;
    min-height: 70px;
}

.headingText {
    color: #fc6225;
    padding-bottom: 4px;
    border-bottom: 3px solid #fc6225;
    font-size: 26px;
    font-weight: bold;
}

.headingText2 {
    font-size: 26px;
}

.hrLine {
    position: absolute;
    top: 45%;
    color: black;
    width: 90%;
}

.user-main-container .btn {
    float: right;
    padding-inline: 30px;
}

._25P-K {
    pointer-events: block !important;
    z-index: 9999 !important;
}