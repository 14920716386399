.card-header {
    color: #fff;
    border: none;
}

.card-body {
    background-color: #FEFEFE;
}

.bg-green {
    background-color: #02A0A0;
}

.bg-blue {
    background-color: #4E5DA3;
}

.bg-orange {
    background-color: #FF7752;
}

.bg-pink {
    background-color: #FF4697;
}

.text-green {
    color: #02A0A0;
}

.text-blue {
    color: #4E5DA3;
}

.text-orange {
    color: #FF7752;
}

.text-pink {
    color: #FF4697;
}


button.card-button  {
    border: transparent !important;
    background-color: #FFFFFF !important;
}
button.card-button:focus,
button.card-button:hover {
    outline: none !important;
    box-shadow: none !important;
    background-color: #FF7752 !important;
}