 
.address-details-form label {
    /* font-family: 'Titillium Web'; */
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 24px;
display: flex;
align-items: center;
color: #7E7E7E;
cursor: pointer;
}
.address-details {
    border: 1px solid #DEE2E6;
    height: auto;
}
.address-details-form input[type='text'] {
    border-bottom: 1px solid #AFAFAF ;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none; 
    width: 100%;
}
.address-details .address-btns button {
    /* font-family: 'Titillium Web'; */
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    background-color: transparent;
    border: none;
    color: #7D879E;
    letter-spacing: 0.01em;
    text-transform: capitalize;
}
.address-details-form input[type='checkbox'] {
    cursor: pointer;
}

input::placeholder {
    /* font-family: 'Titillium Web'; */
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
}
