.general-details-form label {
    /* font-family: 'Titillium Web'; */
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 24px;
display: flex;
align-items: center;
color: #7E7E7E;
cursor: pointer;
}

.general-details {
    border: 1px solid #DEE2E6 ;
    height: auto;
}

.general-details-form input[type='number'] {
    border-bottom: 1px solid #AFAFAF ;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none; 
}
.general-details-form input[type='text'] {
    border-bottom: 1px solid #AFAFAF ;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none; 
}

input::placeholder {
    /* font-family: 'Titillium Web'; */
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
}
