.general_info .lynkaz_tab_nav_section{
    z-index: 100 !important;
}

.setup .lynkaz_tab_nav_section{
    z-index: 100 !important;
}

.delivery .lynkaz_tab_nav_section{
    z-index: 100 !important;
}

.list_tabview .lynkaz_tab_nav_section{
    z-index: 100 !important;
}
 
.list_tabview #lynk_tab_m_id_list_view_parent_tab .lynkaz_tab_nav{
    margin-bottom: 0 !important;
  gap: 10px 10px !important;
}
.list_tabview #lnk_tb_list_view_children_tab{
    border: 1px solid #DEE8F4; 
}
.list_tabview #lnk_tb_list_view_children_tab .lynkaz_tab_nav{ 
    margin-bottom: 0 !important;
}
.list_tabview #lynk_tab_m_id_list_view_parent_tab .lynkaz_tab_nav_item{
    background: #ECECEC;
    border-radius: 4px 4px 0px 0px;
    padding: 8px;
    gap: 8px; 
    width: auto;
    height: 40px; 
}
.list_tabview #lynk_tab_m_id_list_view_parent_tab .lynkaz_tab_nav_item p {
    color: #000000;
}
.list_tabview #lynk_tab_m_id_list_view_parent_tab .lynkaz_tab_nav_item.active p {
    color: #ffffff;
}
.list_tabview #lynk_tab_m_id_list_view_parent_tab .lynkaz_tab_nav_item.active{ 
    background: #FC5E24; 
}
.list_tabview {
    height: auto;
    margin-bottom: 8rem;
}
.purchase_orders_lines_list_view_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}
 
.purchase_orders_lines_list_view_distribution_general_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}
.purchase_orders_lines_list_view_billing_rule_grid  .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.list_tabview #lnk_tb_list_view_children_tab .lynkaz_tab_nav {
    padding-left: 1.1rem;
}

.list_view_action_btns_grid button {
background-color: transparent;
border: none;
font-weight: 600;
font-size: 16px;
color: #333333;
} 

.general_info .vendor h3 {
    font-weight: 600;
    width: 53px;
font-size: 16px; 
border-bottom: 1px solid #2D74E0;
padding-bottom: 5px;
color: #000000;
}

.general_info .document_date h3{
    font-weight: 600;
    width: 115px;
font-size: 16px; 
border-bottom: 1px solid #2D74E0;
padding-bottom: 5px;
color: #000000;
}

.view_display_components {
    padding-left: 5px;
}

.view_display_components .view_btns button {
    background : transparent;
    border: none;
    font-weight: 400;
    font-size: 18px; 
    color: #58585A;
}  
.view_display_components .view_btns .view_btn_class_active {
    color: #FC5E24 !important;
    border-bottom: 3px solid #FC5E24;
    padding-bottom: 5px 
}
.view_display_components .view_btns .view_btn_class {
    color: #58585A;
}
.img_class_active {
    color: #000000;
}
.img_class{
    color: #2D74E0;
}
.list_view_class > div> .lynkaz_tab_nav_section .lynkaz_tab_nav{
    display: none;
}

.general_info .tax_info  h3{
    font-weight: 600;
    width: 117px;
font-size: 16px; 
border-bottom: 1px solid #2D74E0;
padding-bottom: 5px;
color: #000000;
}

.close-popup_po h2 { 
font-size: 20px; 
font-weight: 600;
letter-spacing: 0.01em; 
color: #303030;
}
  
.close-popup_po .action_btns_po button{
    width: 124px;
    height: 40px;
    border: 1px solid #EBEFF2;
    border-radius: 4px; 
}

.close-popup_po .create_btn {
    background: #2D74E0; 
    color: #ffffff;
}

.close-popup_po .cancel_btn {
    background: #FFFFFF; 
    color: #000000;
}

