/* .new_numbers_sequence label {
    font-family: 'Titillium Web';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 24px;
display: flex;
align-items: center;
color: #7E7E7E;
} */
/* 
.numbers_sequence_edit label {
    font-family: 'Titillium Web';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 24px;
display: flex;
align-items: center;
color: #7E7E7E;
}
   */
.new_numbers_sequence {
    min-height: 130vh;
    max-height: auto;
}

.numbers_sequence_edit { 
    min-height: 98vh;
    max-height: auto;
}
/* 
.numbers_sequence_edit input[type='text'] {
    border-bottom: 1px solid #7E7E7E;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
    width: 100%;
}

.numbers_sequence_edit input[type='number'] {
    border-bottom: 1px solid #7E7E7E;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
    width: 100%;
}

.numbers_sequence_edit input::placeholder {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
}

.numbers_sequence_edit input {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
} 


.new_numbers_sequence input[type='text'] {
    border-bottom: 1px solid #7E7E7E;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
    width: 100%;
}

.new_numbers_sequence input[type='number'] {
    border-bottom: 1px solid #7E7E7E;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
    width: 100%;
}

.new_numbers_sequence input::placeholder {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
}

.new_numbers_sequence input {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
}  */


/* .format { 
    border-bottom: 1px solid #7E7E7E !important;
} */

.rs_new_number_sequence .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_numbers_sequence_edit .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

 /* .new_numbers_sequence .rs_dropdown {
   border-bottom:1px solid #7E7E7E ; 
} */

  /* .new_numbers_sequence .dropdown_label { 
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #414141;
} */

/* .numbers_sequence_edit .rs_dropdown { 
    border-bottom:1px solid #7E7E7E ; 
}  */
 
/* .numbers_sequence_edit  .dropdown_label {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #414141;
} */

.new_numbers_sequence .form-group select.form-control {
    font-size: 12px !important;
}

.numbers_sequence_edit .form-group select.form-control { 
    font-size: 12px !important;
}
