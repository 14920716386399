.u-main-container {
    width: 600px;
    position: relative;
}

.u-togglrArrow {
    position: absolute;
    color: white;
    background-color: #fc6225;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    text-align: center;
    left: -10px;
    top: 3px;
}

.u-mainHeading {
    color: #fc6225;
    padding-bottom: 4px;
    border-bottom: 3px solid #fc6225;
}

.u-rightTopButton {
    border-radius: 5px;
    background-color: #eec4b528;
    padding: 4px 12px;
}

.u-innerContains span {
    font-size: 22px;
}

.u-innerContains p {
    font-size: 20px;
    font-weight: bold;
}