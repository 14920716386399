.rs_dropdown {
	position: relative;
}
.rs_dropdown .dropdown_label {
	display: flex;
	color: #FC5E24;
	/* font-family: 'Titillium Web'; */
	font-size: 18px;
	font-weight: 700;
	gap: 15px;
	justify-content: space-between;
	min-width: 200px;
	cursor: pointer;
	align-items: center;
}
.rs_dropdown .dropdown_label .icon {
	height: 10px;
	transition: all .5s;
}
.rs_dropdown.open .dropdown_label .icon {
	transform: rotate(180deg);
}
.rs_dropdown .dropdown_items {
	position: absolute;
	left: 0;
	top: 100%;
	z-index: 1000;
	list-style: none;
	margin: 0;
	padding: 0;
	background: #fff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	min-width: 100%;
}
.rs_dropdown .dropdown_items li {
	padding: 7px 20px;
	font-size: 14px;
	/* font-family: 'Titillium Web'; */
	font-weight: 400;
	color: #757575;
	display: block;
	cursor: pointer;
    transition: all .5s;
}
.rs_dropdown .dropdown_items li:hover{
    background-color: #1F78FF;
    color: #fff;
}