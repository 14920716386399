.form-container {
    padding: 1rem;
    width: 96%;
    z-index: 30;
    background: #ffffff; 
    border: 1px solid #DEE8F4;
    max-height: auto;
    min-height: 50vh;
}

.rs_organisation_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_currency_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}
.grid-contain .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_currency_conversion_rates_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_form_grid  .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_form_resources_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}


.rs_languages_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_label_forms_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_module_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_previleges_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_resources_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_resources_access_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs-user-roles-grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs-unit-conversion-grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs-unit-grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_banks_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}


.previleges_assignment_save .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_dimensions_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_color_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_item_buyer_group_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_item_group_account_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_item_group_account_grid  .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_item_group_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}


.rs_item_group_account_type_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}


.rs_size_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_configuration_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}
 
.rs_brand_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_configuration_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.rs_style_grid .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}