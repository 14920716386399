.round-flow {
    color: #fff;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

.start-flow-bg {
    background-color: #FFCD29;
}

.end-flow-bg {
    background-color: #303030;
}

/* .react-flow__node.end-flow-bg .react-flow__handle, */
.react-flow__node.flow-add-wrapper .react-flow__handle {
    display: none;
}

/* .react-flow .react-flow__handle.react-flow__handle-top {
    display: none;
    opacity: -9999;
} */

.action-type__step-node {
    width: 120px;
    padding: 50px 15px 25px;
    border-radius: 2px;
    border: transparent;
    color: #fff;
}

.title {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
}

.react-flow__attribution {
    display: none;
}

.react-flow .react-flow__handle {
    width: 15px;
    height: 15px;
    background-color: #2D74E0;
}

.react-flow .react-flow__handle::before {
    content: "+";
    position: absolute;
    transform: translate(-50%, -50%);
    top: 45%;
    left: 50%;
}

.flow-add-wrapper {
    border: none;
    padding: 5px 3px;
    background-color: #E6E6E6;
    border-radius: 4px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
    width: auto;
}

.flow-add-container .card {
    width: 100%;
    max-width: 140px;
}

.flow-add-container .card {
    margin: 0 3px
}

.btn-node-save {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 4;
    font-size: 16px;
    border: 1px solid;
    border: transparent;
    background-color: #FF7752;
}

.btn-node-save:hover,
.btn-node-save:focus {
    background-color: #FF7752;
}

.btn-node-restore {
    position: absolute;
    left: 75px;
    top: 10px;
    z-index: 4;
    font-size: 16px;
    border: 1px solid;
    border: transparent;
    background-color: #FF7752;
}

.btn-node-save:focus,
.btn-node-save:hover {
    background-color: #FF7752;
}

.deactive-main-container {
    animation: fromleft 1 backwards .5s;
    transition: all 5s ease-in-out;
}

.active-main-container {
    transition: all 5s ease-in-out;
    animation: fromRight .5s forwards 1;
}

@keyframes fromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
    }
}

/* @keyframes fromleft{
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
} */