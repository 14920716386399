.tenant-header .tenant-title h1 {
/* font-family: 'Titillium Web'; */
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 130%;
letter-spacing: 0.02em;
color: #000000;
}

.tenant-btns-create {
    background: #FC5E24;
border: 1px solid #FC5E24;
border-radius: 4px;
/* font-family: 'Titillium Web'; */
font-style: normal;
font-weight: 600;
font-size: 14px;
display: flex;
align-items: center;
text-transform: capitalize;
border: none;
color: #ffffff;
width: 70px;
height: 32px;
}
.tenant-btns-search {
    background: #ffffff;
border-radius: 4px;
/* font-family: 'Titillium Web'; */
font-style: normal;
font-weight: 600;
font-size: 14px;
display: flex;
align-items: center;
text-transform: capitalize;
border: none;
color: #000000;
border: 1px solid #000000;
width: 78px;
height: 32px;
}

 
