.assignment_tab_item {
    color: #000000;
    background-color: #ECECEC;
    ;
}

.assignment_tab_item:focus,
.assignment_tab_item:hover {
    color: #FC5E24;
}

.assignment_tab_menu .assignment_tab_item.nav-link.active {
    color: #FFFFFF;
    background-color: #FC5E24;
}