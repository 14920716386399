.rs_ag_header {
	padding: 20px 0;
}
.rs_ag_header .left {
	align-items: center;
	justify-items: center;
	display: flex;
}
.rs_ag_header .right {
	align-items: center;
}
.rs_ag_header .rs_ppp_select{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #333333;
    display: flex;
    justify-items: center;
    align-items: center;
}
.rs_ag_header .rs_ppp_select span{}
.rs_ag_header .rs_ppp_select select {
	margin: 0 6px;
	height: 36px;
	font-size: 13px;
	padding: 0 12px;
	border: 1px solid #E0E7ED;
	border-radius: 4px;
	width: 75px;
}
.ctl-dissable{
	opacity: 0.5;
}
.rs_ag_header .rs_h_controllers{}
.rs_ag_header .rs_h_controllers .rs_h_controller {
	margin: 0;
	padding: 0;
	display: block;
	overflow: hidden;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #242533;
	cursor: pointer;
	margin-right: 16px;
}
 .save-button { 
    color: #2D74E0  !important;
}
.rs_ag_header .rs_h_controllers .rs_h_controller img {
	display: block;
	float: left;
	margin: 0;
	padding: 0;
	height: auto;
	width: 20px;
}
.rs_ag_header .rs_h_controllers .rs_h_controller span {
	display: block;
	overflow: hidden;
	line-height: 20px;
	padding-left: 7px;
}

.rs_ag_header .rs_gh_search_box{}
.rs_ag_header .rs_gh_search_box input {
	width: 250px;
	height: 36px;
	border: 1px solid #738CAB;
	font-size: 12px;
	color: #738CAB;
	box-shadow: none !important;
	outline: none !important;
	background-image: url('../../../assets/search.svg');
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: 9px center;
	background-size: 13px;
	padding: 0 0 0 34px;
}
.rs_ag_grid_panel .rs_h_actions{}
.rs_ag_grid_panel .rs_h_actions .rs_h_controller{}
.rs_ag_grid_panel .rs_h_actions .rs_h_controller span {
	font-size: 16px;
	color: #333333;
}
.rs_ag_grid_panel .rs_h_actions .rs_h_controller img{}
.ag_grid_wrapper {
	position: relative;
}
.ag_grid_wrapper .rs_ppp_select {
	display: inline-flex;
	justify-items: center;
	align-items: center;
	gap: 9px;
	left: 0;
}
.ag_grid_wrapper .rs_ppp_select span{}
.ag_grid_wrapper .rs_ppp_select select {
	background: transparent;
	border: 0 none;
	width: 60px;
	padding: 0;
	font-size: 16px;
	text-align: center;
}
.rs_grid_simple_dropdown {
	display: block;
	background: #fff;
	box-sizing: border-box;
	border: 0 none;
	height: 100%;
	padding: 0 10px;
	box-shadow: none !important;
	width: 100%;
}
